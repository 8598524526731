<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-discussion-forum-96.png"
          class="h-20 ml-2 inline-block"
        />
        ویرایش صف
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
        <form @submit.prevent="submit">
          <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
            <MnrText placeholder="یک نام وارد کنید" v-model="name" title="نام صف" />
            <MnrSelect
              v-model="inputs.status.value"
              :options="inputs.status.options"
              title="وضعیت"
              placeholder="انتخاب کنید"
            />
            <MnrSelect
              v-model="inputs.send.value"
              :options="inputs.send.options"
              title="زمانبندی"
              placeholder="انتخاب کنید"
            />
            <MnrSelect
              v-model="inputs.previous.value"
              :options="inputs.previous.options"
              title="عملیات پست قبلی"
              placeholder="انتخاب کنید"
            />
          </div>
          <hr class="border-blueGray-200 my-5" />
          <template v-if="inputs.send.value == 'time'">
            <div class="grid grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8 gap-2 mt-4">
              <div
                v-for="(time, index) in times"
                :key="index"
                class="w-full bg-blueGray-50 border border-blueGray-200 p-2 rounded"
              >
                <Button
                  class="rounded-full h-5 w-5 mb-2 inline-block p-0"
                  color="red"
                  @click="times.splice(index, 1)"
                >
                  ×
                </Button>
                <MnrText v-model="time.value" type="time" />
              </div>
            </div>
            <div class="flex flex-wrap gap-2">
              <Button
                class="mt-3 inline-flex text-lg"
                color="teal"
                @click="times.push({ value: '' })"
              >
                اضافه کردن
              </Button>
            </div>
          </template>
          <template v-if="inputs.send.value == 'period'">
            <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
              <MnrText
                v-model="inputs.start"
                v-show="inputs.send.value == 'period'"
                type="time"
                title="شروع بعدی"
                hint="اگرو شروع بعدی قبل از زمان حاظر باشد روز بعد شروع می‌شود"
              />
            </div>
            <h2 class="text-lg font-bold">دوره تناوب فاصله بین پست ها</h2>
            <div class="grid grid-cols-3 gap-3 mt-2">
              <MnrNumber
                v-model="inputs.period.min"
                hint="دقیقه"
                before="دقیقه"
                placeholder="0"
                :min="0"
                :max="59"
              ></MnrNumber>
              <MnrNumber
                v-model="inputs.period.hour"
                hint="ساعت"
                before="ساعت"
                placeholder="0"
                :min="0"
                :max="59"
              ></MnrNumber>
              <MnrNumber
                v-model="inputs.period.day"
                hint="روز"
                before="روز"
                placeholder="0"
                :min="0"
              ></MnrNumber>
            </div>
            <hr class="border-blueGray-200 my-5" />
            <div>
              <MnrCheckSlider v-model:checked="inputs.sendLimit.status">
                ارسال در بازه زمانی مشخص
              </MnrCheckSlider>
              <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
                <MnrText
                  v-model="inputs.sendLimit.start"
                  v-show="inputs.sendLimit.status"
                  type="time"
                  title="شروع"
                />
                <MnrText
                  v-model="inputs.sendLimit.end"
                  v-show="inputs.sendLimit.status"
                  type="time"
                  title="پایان"
                />
              </div>
            </div>
          </template>
          <div class="sm:w-60 w-full mt-3 mx-auto">
            <Button type="submit" class="mt-3 text-lg" color="sky">ویرایش</Button>
          </div>
        </form>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import _ from "lodash";
import MnrSelect from "@/components/mnr/MnrSelect.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrText,
    MnrSelect,
    MnrNumber,
    MnrCheckSlider,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      queue: {},
      name: "",
      times: [],
      inputs: {
        status: {
          value: 1,
          options: [
            {
              text: "فعال",
              value: 1,
            },
            {
              text: "غیر فعال",
              value: 0,
            },
          ],
        },
        send: {
          value: "period",
          options: [
            {
              text: "دوره‌ای",
              value: "period",
            },
            {
              text: "زمان‌های مشخص",
              value: "time",
            },
          ],
        },
        previous: {
          value: "off",
          options: [
            {
              text: "خاموش",
              value: "off",
            },
            {
              text: "حذف پست قبلی",
              value: "delete",
            },
          ],
        },
        start: null,
        sendLimit: {
          status: false,
          start: null,
          end: null,
        },
        period: {
          min: 0,
          hour: 8,
          day: 0,
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.posts = [];
      $this.$axios
        .get(
          `api/owner-channels/${$this.$route.params.channelId}/queue/${$this.$route.params.queueId}`,
          {
            params: {
              id: $this.$store.state.user.id,
            },
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.queue = response.data.queue;

          $this.name = $this.queue.name;
          $this.times = $this.queue.times;

          $this.inputs.status.value = $this.queue.active;
          $this.inputs.send.value = $this.queue.send_type;
          $this.inputs.previous.value = $this.queue.previous;
          $this.inputs.start = $this.queue.next_at;
          $this.inputs.period.day = Math.floor($this.queue.send_period / (24 * 60));
          $this.inputs.period.hour = Math.floor(
            ($this.queue.send_period % (24 * 60)) / 60
          );
          $this.inputs.period.min = ($this.queue.send_period % (24 * 60)) % 60;
          $this.inputs.sendLimit.status = $this.queue.send_period_limit;
          $this.inputs.sendLimit.start = $this.queue.send_start;
          $this.inputs.sendLimit.end = $this.queue.send_end;

          $this.times = _.map($this.times, function (time) {
            return { value: time.send_time };
          });
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    submit() {
      let $this = this;
      $this.posts = [];
      $this.$axios
        .put(
          `api/owner-channels/${$this.$route.params.channelId}/queue/${$this.$route.params.queueId}`,
          {
            id: $this.$store.state.user.id,
            name: $this.name,
            times: $this.times,
            inputs: $this.inputs,
          }
        )
        .then(function (response) {
          // console.log(response);
          $this.$router.back();
          // $this.$router.replace({
          //   name: "ChannelOwnerSettingQueues",
          //   params: {
          //     id: $this.$store.state.user.id,
          //     channelId: $this.channel.id,
          //   },
          // });
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
